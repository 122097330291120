@use "src/mixins" as *;
@use "src/variables" as *;

.block-3-main-container {
  height: 100%;
  display: flex;
  padding: 20px 0 0px 0;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 45px 0 45px;
  position: relative;
  min-height: 400px;

  &.is-page {
    padding: 30px 0 0 0;
  }

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  .description-choose-img {
    &.hidden {
      background: none !important;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-medium-large) {
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin: 0 15px 0 15px;
  }

  .block-img {
    width: auto;
  }

  h2 {
    /* Headers/H1 Header */

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 143% */
    color: #1D1D1B;

    @include media($mediapoint-small, $mediapoint-medium) {
      text-align: left;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      justify-content: center;
      font-size: 20px;
    }
  }

  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #1D1D1B;
  }

  .how-does-it-work {
    max-width: 460px;

    &.full-width {
      max-width: 100%;
    }

    @include media($mediapoint-small, $mediapoint-medium-large) {
      max-width: 768px;
      width: calc(100% - 90px);
      margin: 0 45px 0 45px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      order: 0;
      padding-bottom: 220px;
    }
  }

  .switch-menu {
    width: 460px;
    height: 51px;
    background: #E4E4E4;
    border-radius: 24px;
    border: 0.5px solid #E0E0E0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    @include media($mediapoint-very-small, $mediapoint-small) {
      min-width: 250px;
      width: 100%;
    }

    >div.point-menu {
      color: #A7A7A7;
      letter-spacing: -0.08px !important;
      cursor: pointer;
      z-index: 2;
      height: 100%;
      width: 33%;
      justify-content: center;
      display: flex;
      align-items: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      display: flex;
      align-items: center;
      text-align: center;

      /* Achromatic/Gray 4 */

      &.active {
        font-family: Manrope;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        display: flex;
        align-items: center;
        text-align: center;

        /* Achromatic/White */

        color: #FFFFFF;
      }
    }

    .point-menu-active {
      position: absolute;
      height: 50px;
      width: 153px;
      top: 0;
      left: 0;
      z-index: 0;
      background: $green;
      /* Button/Switcher */
      box-shadow: 0px 2px 4px 1px rgba(49, 49, 49, 0.08);
      border-radius: 24px;
      border: 1px solid #EAEAEA;
      transition: all 0.4s ease-in-out;
      z-index: 0;

      &[data-active="left"] {
        left: 0;
      }

      &[data-active="middle"] {
        left: 150px;

      }

      &[data-active="right"] {
        left: 305px;

      }

      @include media($mediapoint-very-small, $mediapoint-small) {
        width: 33%;

        &[data-active="left"] {
          left: 0;

        }

        &[data-active="middle"] {
          left: 33%;

        }

        &[data-active="right"] {
          left: 66%;

        }
      }
    }
  }
}

.description-choose {
  margin-bottom: 50px;

  @include media($mediapoint-small, $mediapoint-medium-large) {
    max-width: 768px;
    width: 100%;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    max-width: 460px;
    margin: 0 auto 50px auto;
    min-height: 84px;

    p {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.description-choose-img {
  width: 40vw;
  max-width: 470px;
  margin: 0;
  bottom: -5px;
  left: 0;
  position: absolute;
  background: none;

  img {
    width: 100%;
  }

  @include media($mediapoint-small, $mediapoint-medium-large) {
    width: 100% !important;
    max-width: 355px;

    &[data-img="left"] {
      position: static !important;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;

    max-width: 285px;
  }
}

.description-choose-img {
  &[data-img="left"] {
    background: url("../../../../assets/images/how-to-use-left.png") 50% 40% no-repeat;
    margin-bottom: 25px;
  }

  &[data-img="middle"] {
    background: url("../../../../assets/images/how-to-use-middle.png") 50% 100% no-repeat;
  }

  &[data-img="right"] {
    background: url("../../../../assets/images/how-to-use-middle.png") 50% 100% no-repeat;
  }
}

body.webp {
  .description-choose-img {
    &[data-img="left"] {
      background: url("../../../../assets/images/how-to-use-left.webp") 50% 40% no-repeat;
    }

    &[data-img="middle"] {
      background: url("../../../../assets/images/how-to-use-middle.webp") 50% 100% no-repeat;
    }

    &[data-img="right"] {
      background: url("../../../../assets/images/how-to-use-middle.webp") 50% 100% no-repeat;
    }
  }
}

.box-payment-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 460px;
  width: 100%;
  position: relative;

  @include media($mediapoint-small, $mediapoint-medium-large) {
    flex-direction: column;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    flex-direction: column;
    width: 100%;
  }

  &.banner {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 25px;

    @include media($mediapoint-small, $mediapoint-medium-large) {
      margin: 0;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      margin: 4px 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tooltip {
    position: absolute;
    top: 0px;
    right: 0px;
    pointer-events: none;
    display: none;

    &.active {
      display: block;
    }

    &__body {
      opacity: 0;
      background-color: white;
      padding: 5px;
      border-radius: 10px;
      position: absolute;
      font-family: Manrope;
      font-size: 14px;
      z-index: 9999;
      top: -130px;
      right: 465px;
      width: 300px;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

      @include media($mediapoint-medium, $mediapoint-medium-large) {
        top: -33px;
        right: 272px;
      }

      @include media($mediapoint-very-small, $mediapoint-medium) {
        display: none;
      }

      &.active {
        opacity: 1;
      }
    }

  }

}

.right-payment {
  width: 270px;

  @include media($mediapoint-very-small, $mediapoint-medium-large) {
    width: auto;
  }
}
.right-block {
  display: flex;
  flex-direction: row;

  @include media($mediapoint-small, $mediapoint-medium-large) {
    flex-direction: column !important;
    margin-top: 50px;
    max-width: 75%;
    margin-bottom: 25px;
  }

  @include media($mediapoint-small, $mediapoint-medium) {
    max-width: 45%;
  }
  @include media($mediapoint-very-small, $mediapoint-small) {
    flex-direction: column;
    width: 100%;
  }  
}

.right-logo-payment {
  width: 95% !important;
  @include media($mediapoint-small, $mediapoint-medium-large) {
    width: 100% !important;
  }  
}
.additional-info {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 460px;
  flex-wrap: wrap;

  @include media($mediapoint-small, $mediapoint-medium-large) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 270px;
    max-width: 265px;

    .additional-info-body {
      max-width: 50%;
    }
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    justify-content: stretch !important;
    flex-direction: column !important;
    align-items: center !important;
    max-width: 460px;
    margin: 0 auto;

    .main-box {
      width: 95% !important;
      max-width: 460px;
      padding: 8px !important;
      margin: 4px auto !important;

      p {
        text-align: left;
      }
    }
  }
}
