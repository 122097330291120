@use "src/mixins" as *;
@use "src/variables" as *;

.block-2 {
  min-height: 320px;
  background: linear-gradient(89.58deg, #4E4E4E 0%, #6D6D6D 88.94%);

  &-main-container {
    position: relative;
    margin: 0 0 0 5px;

    @include media($mediapoint-medium, $mediapoint-large) {
      margin: 0 0 0 45px;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      margin: 0 0 0 45px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      margin: 0 0 0 0px;
    }
  }

  .abs-position-img {
    position: absolute;
    bottom: 0;
    right: -35px;
    width: 455px;
    z-index: 0;

    @include media($mediapoint-medium, $mediapoint-large) {
      right: 0px;
      width: 435px;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      right: 0px;
      width: 350px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      position: relative;
      max-width: 500px;
      right: 15px !important;
      width: 100%
    }
  }

  &-main-container {
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 30px;
    position: relative;

    @include media($mediapoint-very-small, $mediapoint-small) {
      padding-left: 0px;
      padding-top: 40px;
      padding-bottom: 20px;

      &:before {
        content: '';
        height: 23px;
        width: 100%;
        background-color: white;
        position: absolute;
        top: -2px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }


  .title-block {
    /* Headers/H1 Header */

    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 143% */
    margin: 15px 0 10px 0;
    display: flex;
    align-items: center;
    text-align: center;

    /* Chromatic/Orange 1 */

    color: #F7A823;

    @include media($mediapoint-small, $mediapoint-medium) {}

    @include media($mediapoint-very-small, $mediapoint-small) {
      justify-content: center;
      font-size: 20px;
    }
  }

  &-description {
    padding: 10px 0px 10px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    max-width: 870px;
    z-index: 1;

    @include media($mediapoint-medium, $mediapoint-large) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 350px;

      .column-information {
        margin: 25px 10px !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        p {
          text-align: left;
          margin: 0;
        }
      }
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 350px;

      .column-information {
        margin: 25px 10px !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        p {
          text-align: left;
          margin: 0;
          padding: 0 !important;
        }
      }
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      max-width: 350px;

      .column-information {
        margin: 10px 0;

      }
    }

    .column-information {
      text-align: center;
      margin: 10px 10px;

      width: 33%;

      @include media($mediapoint-very-small, $mediapoint-large) {
        width: 100% !important;
      }
      
      .description-column {
        padding: 0px 20px;
      }
    }

    .title-column {
      /* Body/Body L Bold */
      margin: 0px 0 0 0;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #F7A823;

      @include media($mediapoint-very-small, $mediapoint-medium) {
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
      }
    }

    p {
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;
      text-align: center;
      color: #FFFFFF;
    }
  }
}