@use "src/mixins" as *;
@use "src/variables" as *;

.main-carousel-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 100%;
    max-height: 580px;
    object-fit: contain;
  }

  &__slide {
    display: block;
    visibility: hidden;
    height: 0;
  }
}
