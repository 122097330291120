@use "src/variables" as *;
@use "src/mixins" as *;

.menu-bar-bg {
  display: none;
  position: absolute;

  width: calc(50% - 600px);
  height: calc(100% + 72px);
  left: 0px;
  top: -72px;
  background: #F0F0F0;

  &.active {
    display: block;
  }
}

.menu-bar {
  display: none;
  position: fixed;
  min-width: 350px;
  max-width: 700px;
  width: 42%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(240, 240, 240, 1);
  border-radius: 0px 12px 12px 0px;
  z-index: 100;

  &.active {
    display: flex;
    justify-content: flex-end;
  }

  @include media($mediapoint-medium, $mediapoint-medium-large) {
    left: 0px;
    border-radius: 0px 12px 12px 0px;

  }

  @include media($mediapoint-small, $mediapoint-medium) {
    left: 0px;
    border-radius: 0px 12px 12px 0px;

  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px !important;
    margin-top: 20px;
    height: 420px;
    background: rgba(240, 240, 240, 1);
  }

  ul.list-href {
    margin-top: 100px;
    width: 70%;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    list-style: none;
    padding: 0;

    @include media($mediapoint-medium-large, $mediapoint-large) {
      width: 70%;
    }

    @include media($mediapoint-small, $mediapoint-medium-large) {
      width: 100%;
      padding-left: 98px;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      width: 100%;
      margin-top: 70px !important;
    }

    li {
      font-family: Manrope;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      -webkit-tap-highlight-color: transparent;
      padding: 10px 0;

      @include media($mediapoint-very-small, $mediapoint-small) {
        display: flex;
        padding: 0 20px;
        text-align: end;
      }

      @include media($mediapoint-very-small, $mediapoint-small-360) {
        padding: 0;
      }

      a {
        text-decoration: none;
        color: #1D1D1B;
        -webkit-tap-highlight-color: transparent;

        @include media($mediapoint-very-small, $mediapoint-small) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          min-height: 50px;
          width: 100%;
        }

        @include media($mediapoint-very-small, $mediapoint-small-360) {
          justify-content: center;
        }

        &:hover {
          color: #1D1D1B;
        }

        &.active {
          color: $green;
        }
      }
    }

  }

}

.desktop-second-menu {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	padding: 100px 0 0 0;

	@include media($mediapoint-medium, $mediapoint-large) {
		padding: 100px 20px 0 20px;
	}

	@include media($mediapoint-small-360, $mediapoint-medium) {
		padding: 100px 20px 0 20px;
	}

	@include media($mediapoint-very-small, $mediapoint-small-360) {
		padding: 100px 20px 0 20px;
	}

	li {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		-webkit-tap-highlight-color: transparent;
		padding: 10px 10px;

		&:first-child {
			padding: 10px 10px 10px 0px !important;
		}

		&:last-child {
			padding: 10px 0px 10px 10px !important;
		}

		@include media($mediapoint-very-small, $mediapoint-small) {
			padding: 2px 20px;
			text-align: end;
		}

		a {
			text-decoration: none;
			color: #5a5a5a;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				color: #1D1D1B;
			}

			&.active {
				color: $green;
			}
		}
	}

}