@use "src/mixins" as *;
@use "src/variables" as *;

.calculator-blocks {
  display: flex;
  flex-direction: column;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    padding: 0 25px;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    flex-direction: column;
  }

  .calculator-blocks-item {
    background-color: #f0f0f0;
    border-radius: 12px;
    padding: 6px 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    @include media($mediapoint-very-small, $mediapoint-medium) {
      &:first-child {
        margin-top: 0;
      }
    }

    img {
      margin-right: 10px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      font-family: Manrope;

      @include media($mediapoint-very-small, $mediapoint-small) {
        text-align: center;
      }
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      flex-direction: column;
    }
  }
}

.block-1 {
  min-height: 650px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;

  @include media($mediapoint-medium, $mediapoint-large) {
    margin: 0 0px !important;
  }

  @include media($mediapoint-small, $mediapoint-medium) {
    align-items: flex-end;
    justify-content: center;
    height: 100% !important;
    margin-top: 100px;
  }

  @include media($mediapoint-very-small, $mediapoint-small) {
    flex-direction: column;
    height: auto;
    padding-bottom: 0px;
    padding-top: 75px;
  }

  .bg-man {
    width: 50%;
    background: url("../../../../../../assets/images/prestamo-personal-online-instante.png")
      50% 100% no-repeat;
    background-size: contain;
    height: 100%;
    min-height: 650px;
    margin-top: 80px;
    align-self: end;

    @include media($mediapoint-small, $mediapoint-medium) {
      display: none;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      display: none;
    }
  }

  .calculator-slider {
    width: 50%;
    margin: 0 0 50px 25px;
    padding-top: 0px;

    @include media($mediapoint-medium, $mediapoint-large) {
      margin: 0 25px 0 0;
      width: 50%;
    }

    @include media($mediapoint-small, $mediapoint-medium) {
      width: 100%;
      padding-top: 0px;
      z-index: 100;
      margin: 20px 0 25px 0 !important;
    }

    @include media($mediapoint-very-small, $mediapoint-small) {
      width: 100%;
      margin: 0px;
      padding-top: 0px !important;
    }

    h2 {
      /* Headers/H1 Header */
      margin-top: 0;
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $green;

      @include media($mediapoint-small, $mediapoint-medium) {
        text-align: left;
        margin: 10px 45px;
      }

      @include media($mediapoint-very-small, $mediapoint-small) {
        justify-content: center;
        font-size: 20px;
        margin: 0;
        margin-top: 20px;
      }
    }

    p.title {
      font-family: Manrope;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      color: #1d1d1b;

      @include media($mediapoint-small, $mediapoint-medium) {
        text-align: left;
        margin: 10px 0;
      }

      @include media($mediapoint-very-small, $mediapoint-small) {
        text-align: center;
        font-size: 16px;
        margin: 10px 0;
      }
    }

    .calculator {
      border-radius: 12px;

      .calculator__button {
        margin: 8px 0;
        -webkit-tap-highlight-color: transparent;
        text-align: center;
      }

      &__info-loader {
        height: 50px;
      }
    }
  }
}
